
const initialState = {
  isLoadingFile: false,
  excelFile: false,
  errorFile: false,
  successFile: false,
  isVisibleAlertFile: false,
};
export const fileReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "FILE_LOADING":
      return {
        ...state,
        isLoadingFile: true,
        isVisibleAlertFile: true

      };
    case "FILE_SUCCESS":
      return {
        ...state,
        isLoadingFile: false,
        errorFile: false,
        excelFile: payload.file,
        isVisibleAlertFile: true,
        successFile: payload.message,
      };
    case "FILE_ALERT_CLOSE":
      return {
        ...state,
        isVisibleAlertFile: false,
      };
    case "FILE_ERROR":
      return {
        ...state,
        isLoadingFile: false,
        errorFile: payload,
        successFile: false,
        isVisibleAlertFile: true,

      };

    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
