
const initialState = {
  isLoadingPhase: false,
  errorPhase: false,
  successPhase: false,
  phaseData: false
};
export const phaseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "PHASE_LOADING":
      return {
        ...state,
        isLoadingPhase: true,
        phaseData: false
      };
    case "PHASE_SUCCESS":
      return {
        ...state,
        isLoadingPhase: false,
        successPhase: payload.message,
        phaseData: payload.web3Data
      };
    case "PHASE_ERROR":
      return {
        ...state,
        isLoadingPhase: false,
        errorPhase: payload,
        successPhase: false,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
