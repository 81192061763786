
const initialState = {
  isLoadingWallet: false,
  isWrongNetwork: false,
  errorWallet: false,
  successWallet: false,
};

export const walletReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "WALLET_LOADING":
      return {
        ...state,
        isLoadingWallet: true,
        errorWallet: false,
        successWallet: false,
      };
    case "WALLET_DATA":
      return {
        ...state,
        isLoadingWallet: false,
        isWrongNetwork: payload.isWrongNetwork,
        errorWallet: false,
      };
    case "WALLET_MINT":
      return {
        ...state,
        isLoadingWallet: false,
        errorWallet: false,
        successWallet: payload.message,

      };

    case "WALLET_ERROR":
      return {
        ...state,
        isLoadingWallet: false,
        errorWallet: payload,
      };

    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
