
const initialState = {
  isLoadingAdmin: false,
  userList: false,
  errorAdmin: false,
  successAdmin: false,
};
export const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADMIN_LOADING":
      return {
        ...state,
        isLoadingAdmin: true
      };
    case "ADMIN_SUCCESS":
      return {
        ...state,
        isLoadingAdmin: false,
        userList: payload.userList,
        errorAdmin: false,
        successAdmin: payload.message,
      };

    case "ADMIN_ERROR":
      return {
        ...state,
        isLoadingAdmin: false,
        errorAdmin: payload,
      };

    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
