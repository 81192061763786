import React, { Suspense, useState, useEffect } from "react";
import { WalletWeb3Provider } from "./context/WalletWeb3Context";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./utils/ScrollTop";
import Spinner from "./components/Spinner";
import { useSelector } from "react-redux";
import WrongNetwork from "./components/Modal/WrongNetwork";
//
const Home = React.lazy(() => import("./pages/Home"));
const Login = React.lazy(() => import("./pages/Login"));
const Signup = React.lazy(() => import("./pages//Signup"));
const RecoverPassword = React.lazy(() => import("./pages//RecoverPassword"));

const App = () => {
  const [isModalWrongNetworkOpen, setisModalWrongNetworkOpen] = useState(false);
  const { isWrongNetwork } = useSelector(({ wallet }) => wallet);
  useEffect(() => {
    if (isWrongNetwork) {
      setisModalWrongNetworkOpen(() => true);
    } else {
      wrongnetworkModalClose();
    }
  }, [isWrongNetwork]);
  const wrongnetworkModalClose = () => {
    setisModalWrongNetworkOpen(() => false);
  };

  return (
    <Suspense fallback={<Spinner full>Cargando</Spinner>}>
      <WalletWeb3Provider>
        {!!isWrongNetwork && (
          <WrongNetwork
            visible={isModalWrongNetworkOpen}
            onClose={() => wrongnetworkModalClose()}
          />
        )}
        <BrowserRouter>
          <ScrollTop>
            <Routes>
              <Route path="/" element={<Login />} exact />
              <Route path="/signup" element={<Signup />} exact />
              <Route path="/recover" element={<RecoverPassword />} exact />
              <Route path="/home" element={<Home />} exact />
              <Route path="*" element={<Home />} exact />
            </Routes>
          </ScrollTop>
        </BrowserRouter>
      </WalletWeb3Provider>
    </Suspense>
  );
};

export default App;
