import React, { useContext } from "react";
import Modal from "../";
import Button from "../../Button";
import { useDispatch } from "react-redux";
import { setWalletNetwork } from "../../..//store/Reducers/Wallet/actions";
import { WalletWeb3Context } from "../../../context/WalletWeb3Context";

const WrongNetwork = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const { library } = useContext(WalletWeb3Context);
  return (
    <Modal visible={visible} onClose={onClose}>
      <div
        className="aic anim"
        style={{ flexDirection: "column", textAlign: "center", gap: "16px" }}
      >
        Parece Que estas en la red equivocada,
        <br />
        por favor cambia a la red
        <Button
          style={{ color: "white" }}
          secondary
          onClick={() => setWalletNetwork(dispatch, library)}
        >
          Cambiar Network
        </Button>
      </div>
    </Modal>
  );
};

export default WrongNetwork;
