import { MainNet, TestNet } from "../../../utils/constants";
import { paxios } from "../../../utils/axios";

export const claimCoins = async (dispatch) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await paxios.post(`/web3/mint`);
    let message = "Tokens Reclamados"
    if (data?.data === "No hay saldo") {
      message = "No tienes Mahoucoins para reclamar"
    } else if (data?.data === "No ha conectado su wallet") {
      message = "No has conectado tu billetera digital"
    } else {
      message = "Ya tienes tus mahoucoins en tu billetera digital"

    }
    // if (!!data.error) {
    //   throw new Error(data.error)
    // }
    dispatch({
      type: "WALLET_MINT",
      payload: {
        message
      },
    });
  } catch (error) {
    console.log(error.toString())
    dispatch({
      type: "FILE_ERROR",
      payload: error.toString(),
    });
  }

};

export const setWalletData = async (dispatch, network, walletAddress, user) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    console.log(user)
    if (user !== undefined & !user?.wallet) {
      const { data } = await paxios.post(`/user/wallet`, {
        wallet: walletAddress
      });
      delete data.data.password
      let user = data.data
      dispatch({
        type: "SECURITY_UPDATE_WALLET",
        payload: {
          user,
        },
      });

    }
    let isWrongNetwork = false;
    let chainId =
      process.env.REACT_APP_NETWORK_ENV === "mainnet"
        ? process.env.REACT_APP_MAINNET_CHAINID
        : process.env.REACT_APP_TESTNET_CHAINID;

    if (network.chainId !== parseInt(chainId)) {
      isWrongNetwork = true;
    } else {
      // setAdminData(dispatch, libraryWeb3, walletAddress);

    }

    dispatch({
      type: "WALLET_DATA",
      payload: {
        isWrongNetwork,
      },
    });
  } catch (error) {
    console.log(error)
    dispatch({
      type: "WALLET_ERROR",
      payload: error,
    });
  }
};
export const setWalletNetwork = async (dispatch, library) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    await library.provider.request({
      method: "wallet_addEthereumChain",
      params: [
        process.env.REACT_APP_NETWORK_ENV === "mainnet" ? MainNet : TestNet,
      ],
    });
  } catch (error) {
    dispatch({
      type: "WALLET_ERROR",
      payload: error.message,
    });
  }
};

export const setWalletDisconnect = async (dispatch) => {
  dispatch({
    type: "DISCONNECT",
  });
};
export const setWalletError = async (dispatch, error) => {
  dispatch({
    type: "WALLET_ERROR",
    payload: error,
  });
};

// export const checkversion = async () => {
//   const { data } = await paxios.get("/version");
// };
