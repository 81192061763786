import React from "react";
import "./style.css";
const Button = ({ children, secondary, extraclassname, ...props }) => {
  return (
    <button
      {...props}
      className={` aic anim scale10 ${
        secondary ? "button-secondary" : "button--container"
      }   ${extraclassname || ""}`}
    >
      {children}
    </button>
  );
};

export default Button;
