import React, { useEffect, useCallback, memo } from "react";
import "./style.css";
const Modal = ({ visible, onClose, children }) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  if (visible)
    return (
      <>
        <div
          id="modal"
          className="modal--backdrop "
          onClick={() => onClose()}
        />
        <div className="modal--container aic anim">
          <div className="modal-body aic anim ">{children}</div>
        </div>
      </>
    );
};

export default memo(Modal);
