
const initialState = {
  isLoadingPremio: false,
  errorPremio: false,
  successPremio: false,
  dataPremio: false,
};
export const premioReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "PREMIO_LOADING":
      return {
        ...state,
        isLoadingPremio: true,
        errorPremio: false,
        successPremio: false,

      };
    case "PREMIO_MESSAGE":
      return {
        ...state,
        successPremio: payload.message,
      };
    case "PREMIO_SUCCESS":
      return {
        ...state,
        isLoadingPremio: false,
        errorPremio: false,
        successPremio: payload.message,
      };
    case "PREMIO_FETCH_BALANCE":
      return {
        ...state,
        isLoadingPremio: false,
        errorPremio: false,
        dataPremio: payload.web3Data,

      };
    case "PREMIO_ERROR":
      return {
        ...state,
        isLoadingPremio: false,
        errorPremio: payload,
        successPremio: false,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
