import { combineReducers } from "redux";
import { walletReducer } from "./Wallet";
import { securityReducer } from "./security";
import { fileReducer } from "./file";
import { phaseReducer } from ".//Phase";
import { adminReducer } from ".//admin";
import { premioReducer } from ".//premio";

export const rootReducer = combineReducers({
  wallet: walletReducer,
  security: securityReducer,
  file: fileReducer,
  phase: phaseReducer,
  admin: adminReducer,
  premio: premioReducer,
});
